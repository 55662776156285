import React, {useCallback, useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useStore} from "../../store/store";
import Picker from "react-mobile-picker";
import {format} from "date-fns";

const monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

function getDayArray(year, month) {
    const dayCount = new Date(year, month, 0).getDate()
    return Array.from({ length: dayCount }, (_, i) => String(i + 1).padStart(2, '0'))
}

function Page() {
    const navigate = useNavigate();
    const motivation = useStore((state) => state.motivation)
    const when = useStore((state) => state.when)
    const setWhen = useStore((state) => state.setWhen)
    const [formattedDate, setFormattedDate] = useState(format(new Date(), 'dd MMM yyyy'))
    const [pickerValue, setPickerValue] = useState(when)


    const handlePickerChange = useCallback((newValue, key) => {
        const { year, month } = newValue
        const newDayArray = getDayArray(Number(year), Number(month))
        const newDay = newDayArray.includes(newValue.day) ? newValue.day : newDayArray[newDayArray.length - 1]
        setPickerValue({ ...newValue, day: newDay })
        const formatted = format(new Date(newValue.year + '-' + newValue.month+'-'+ newDay +'T00:00:00'), 'dd MMM yyyy');
        setWhen({
            year: newValue.year,
            month: newValue.month,
            day: newDay
        });
        setFormattedDate(formatted);
    }, [])

    // const pickerAnimate = {
    //     enter: {
    //         scaleY: 1,
    //         transition: {
    //             duration: 0.2,
    //             ease: 'easeIn'
    //         },
    //         display: "block"
    //     },
    //     exit: {
    //         scaleY: 0,
    //         transition: {
    //             duration: 0.2,
    //             ease: 'easeOut'
    //         },
    //         transitionEnd: {
    //             display: "none"
    //         }
    //     }
    // };

    return (
        <div className={'page when'}>
            <div className={'back'} onClick={() => navigate('/motivation')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                {
                    (motivation && motivation !== 'No') ? (
                        <div className={'title'}>Great! When is the <span style={{textTransform: 'lowercase'}}>{motivation}</span>?</div>
                    ) : (
                        <div className={'title'}>When would you like to reach your ideal weight?</div>
                    )
                }

                <div className={'description'}>
                    This will help us create custom weight-loss plan for you
                </div>
            </div>
            <div className="controls">
                <input type="text" readOnly={true} value={formattedDate} placeholder={'Input date...'} />
                <div className={'line'}></div>
            </div>
            <div className={'picker-wrapper'}>
                {
                    formattedDate && (<div className={'nextPicker'} onClick={() => navigate('/goal')}>Next</div>)
                }
                <Picker
                    value={pickerValue}
                    onChange={handlePickerChange}
                    wheelMode="natural"
                    height={150}
                    className={'data-picker'}
                >
                    <Picker.Column name="month" className={'col'}>
                        { Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')).map((month, index) => (
                            <Picker.Item key={month} value={month} className={'item'}>
                                {({ selected }) => (
                                    <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{monthsArray[index]}</div>
                                )}
                            </Picker.Item>
                        ))}
                    </Picker.Column>
                    <Picker.Column name="day" className={'col'}>
                        { getDayArray(Number(pickerValue.year), Number(pickerValue.month)).map((day) => (
                            <Picker.Item key={day} value={day} className={'item'}>
                                {({ selected }) => (
                                    <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{day}</div>
                                )}
                            </Picker.Item>
                        ))}
                    </Picker.Column>
                    <Picker.Column name="year" className={'col'}>
                        { Array.from({ length: 128 }, (_, i) => `${1923 + i}`).map((year) => (
                            <Picker.Item key={year} value={year} className={'item'}>
                                {({ selected }) => (
                                    <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{year}</div>
                                )}
                            </Picker.Item>
                        ))}
                    </Picker.Column>
                </Picker>
            </div>


            {/*<motion.div*/}
            {/*    className={'picker-wrapper'}*/}
            {/*    variants={pickerAnimate}*/}
            {/*    initial={'exit'}*/}
            {/*    animate={isOpen ? 'enter' : 'exit'}*/}
            {/*>*/}

            {/*</motion.div>*/}
        </div>
    );
}

export default Page;

