import { create } from 'zustand'

export const useStore = create((set) => ({
    gender: undefined,
    born: undefined,
    name: undefined,
    height: {
        value: 175,
        ft: undefined,
        in: undefined,
        type: 'sm'
    },
    weight: {
        value: 90,
        type: 'kg'
    },
    loosGoal: undefined,
    idealWeight: {
        value: 60,
        type: 'kg'
    },
    motivation: undefined,
    when: {
        year: '2024',
        month: '05',
        day: '12',
    },
    email: undefined,
    mealsCount: undefined,
    prepareMeals: [],
    dailyDrinks: [],
    crabDense: [],
    preferences: [],
    habits: [],
    typicalLunch: [],
    vegetableIntake: [],
    workSchedule: [],
    yourLifestyle: [],
    goForWalks: [],
    workOut: [],
    sleepPerNight: [],
    improveYourSleep: [],
    changeMotivation: undefined,
    statement: undefined,
    atThisMoment: undefined,
    setGender: (gender) => set(() => ({ gender: gender })),
    setBorn: (born) => set(() => ({ born: born })),
    setName: (name) => set(() => ({ name: name })),
    setHeight: (height) => set(() => ({ height: height })),
    setWeight: (weight) => set(() => ({ weight: weight })),
    setLoosGoal: (loosGoal) => set(() => ({ loosGoal: loosGoal })),
    setIdealWeight: (idealWeight) => set(() => ({ idealWeight: idealWeight })),
    setMotivation: (motivation) => set(() => ({ motivation: motivation })),
    setWhen: (when) => set(() => ({ when: when })),
    setEmail: (email) => set(() => ({ email: email })),
    setMealsCount: (mealsCount) => set(() => ({ mealsCount: mealsCount })),
    setPrepareMeals: (prepareMeals) => set(() => ({ prepareMeals: prepareMeals })),
    setDailyDrinks: (dailyDrinks) => set(() => ({ dailyDrinks: dailyDrinks })),
    setCrabDense: (crabDense) => set(() => ({ crabDense: crabDense })),
    setPreferences: (preferences) => set(() => ({ preferences: preferences })),
    setHabits: (habits) => set(() => ({ habits: habits })),
    setTypicalLunch: (typicalLunch) => set(() => ({ typicalLunch: typicalLunch })),
    setVegetableIntake: (vegetableIntake) => set(() => ({ vegetableIntake: vegetableIntake })),
    setWorkSchedule: (workSchedule) => set(() => ({ workSchedule: workSchedule })),
    setYourLifestyle: (yourLifestyle) => set(() => ({ yourLifestyle: yourLifestyle })),
    setGoForWalks: (goForWalks) => set(() => ({ goForWalks: goForWalks })),
    setWorkOut: (workOut) => set(() => ({ workOut: workOut })),
    setSleepPerNight: (sleepPerNight) => set(() => ({ sleepPerNight: sleepPerNight })),
    setImproveYourSleep: (improveYourSleep) => set(() => ({ improveYourSleep: improveYourSleep })),
    setChangeMotivation: (changeMotivation) => set(() => ({ changeMotivation: changeMotivation })),
    setStatement: (statement) => set(() => ({ statement: statement })),
    setAtThisMoment: (atThisMoment) => set(() => ({ atThisMoment: atThisMoment })),
}))