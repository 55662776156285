import React, {useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {AiService} from "../../services/ai.service";

function Page() {
    const navigate = useNavigate();
    const [base64Image, setBase64Image] = useState(undefined);
    const [points, setPoints] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChangeImage = (element) => {
        let file = element.files[0];
        const reader = new FileReader();
        reader.onloadend = function() {
            setBase64Image(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleProcess = () => {
        setLoading(true);

        if (base64Image) {
            processImageQuery.mutate(base64Image);
        }
    }

    const processImageQuery = useMutation({
        mutationFn: (data) => {
            return AiService.processImage({image: data});
        },
        onSuccess: (result) => {
            if (result.data.food_items) {
                setPoints(result.data.food_items);
            } else if (result.data.foods) {
                setPoints(result.data.foods);
            }
            setLoading(false);
        },
        onError: (error) => {
            console.log(error);
            setLoading(false)
        }
    });

    return (
        <div className={'page analysis'}>
            {loading && <img className={'loading'} src="/assets/images/loading.svg" alt="" />}
            {base64Image && (
                <div className={'wrapper-image'}>
                    {
                        points.length > 0 && points.map((el) => {
                            return <div className={'point'} style={{top: el.coordinates.y * 100 + '%', left: el.coordinates.x * 100 + '%'}}>
                                <span>{el.name}</span>
                            </div>
                        })
                    }
                    <img className={'image'} src={base64Image} alt="" />
                </div>
            )}
            <div className={'controls'}>
                <input disabled={loading} type="file" onChange={e => handleChangeImage(e.target)}/>
                {base64Image && (<button disabled={loading} onClick={() => handleProcess()}>Process</button>)}

            </div>

        </div>
    );
}
export default Page;

