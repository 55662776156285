// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.goal .wrapper-graph {
  padding: 0 15px;
}
.goal .wrapper-graph .graph {
  margin: 0 auto;
  background-color: #fff;
  padding: 45px 25px;
  border-radius: 16px;
  max-width: 450px;
  position: relative;
}
.goal .wrapper-graph .graph img {
  width: 100%;
}
.goal .wrapper-graph .graph span {
  font-family: "SF Pro Rounded", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.goal .wrapper-graph .graph span.weight {
  position: absolute;
  left: 20px;
  top: 25px;
}
.goal .wrapper-graph .graph span.goal-weight {
  position: absolute;
  right: 20px;
  top: 45%;
}
@media (max-width: 370px) {
  .goal .wrapper-graph .graph span.goal-weight {
    top: 40%;
  }
}
.goal .wrapper-graph .graph span.now {
  position: absolute;
  left: 20px;
  bottom: 25px;
  color: #919CA4;
}
.goal .wrapper-graph .graph span.goal-date {
  position: absolute;
  right: 20px;
  color: #919CA4;
  bottom: 25px;
}
.goal .goal .title span {
  color: #8FDC13;
}
.goal .next-wrapper {
  position: initial;
  bottom: 40px;
  left: 0;
  right: 0;
  top: initial;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/goal/style.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AACI;EACE,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AACN;AAAM;EACE,WAAA;AAER;AACM;EACE,yCAAA;EACA,eAAA;EACA,gBAAA;AACR;AACQ;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AACV;AAEQ;EACE,kBAAA;EACA,WAAA;EACA,QAAA;AAAV;AAEU;EALF;IAMI,QAAA;EACV;AACF;AAEQ;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,cAAA;AAAV;AAGQ;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;AADV;AASM;EACE,cAAA;AAPR;AAYE;EACE,iBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,UAAA;AAVJ","sourcesContent":[".goal {\n  .wrapper-graph {\n    padding: 0 15px;\n    .graph {\n      margin: 0 auto;\n      background-color: #fff;\n      padding: 45px 25px;\n      border-radius: 16px;\n      max-width: 450px;\n      position: relative;\n      img {\n        width: 100%;\n      }\n\n      span {\n        font-family: \"SF Pro Rounded\", sans-serif;\n        font-size: 16px;\n        font-weight: 600;\n\n        &.weight {\n          position: absolute;\n          left: 20px;\n          top: 25px;\n        }\n\n        &.goal-weight {\n          position: absolute;\n          right: 20px;\n          top: 45%;\n\n          @media (max-width: 370px) {\n            top: 40%;\n          }\n        }\n\n        &.now {\n          position: absolute;\n          left: 20px;\n          bottom: 25px;\n          color: #919CA4;\n        }\n\n        &.goal-date {\n          position: absolute;\n          right: 20px;\n          color: #919CA4;\n          bottom: 25px;\n        }\n      }\n    }\n  }\n\n  .goal {\n    .title {\n      span {\n        color: #8FDC13;\n      }\n    }\n  }\n\n  .next-wrapper {\n    position: initial;\n    bottom: 40px;\n    left: 0;\n    right: 0;\n    top: initial;\n    z-index: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
