import React, {useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import {MultiCheck} from "../../components/ui/multi-check/multi-check";
import {MultiOption} from "../../components/ui/multi-check/option";
function Page() {
    const navigate = useNavigate();
    const [next, setNext] = useState(false);
    const habits = useStore((state) => state.habits)
    const setHabits = useStore((state) => state.setHabits)

    return (
        <div className={'page prepare-meals'}>
            <div className={'back'} onClick={() => navigate('/preferences')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What are your food preferences?</div>
            </div>

            <div className={'controls'}>
                <MultiCheck defaultValue={habits} onChange={(value) => {
                    if (value.length > 0) {
                        setNext(true)
                    } else {
                        setNext(false)
                    }
                    setHabits(value)
                }}>
                    <MultiOption value={'I like a vine'}>🍷  I like a vine</MultiOption>
                    <MultiOption value={'I eat late at night'}>🌙  I eat late at night</MultiOption>
                    <MultiOption value={'sweet tooth'}>🧁   I'l have a raging sweet tooth</MultiOption>
                    <MultiOption value={'I get snack attaсks'}>🍪  I get snack attaсks</MultiOption>
                    <MultiOption value={'I cant live  without soda'}>🥤   I can't live without soda</MultiOption>
                    <MultiOption value={'I like crunchy'}>🥨   I like crunchy</MultiOption>
                    <MultiOption value={'I mix screen time with mealtime'}>💻   I mix screen time with mealtime</MultiOption>
                    <MultiOption value={'None of the above'}>🙅️   None of the above</MultiOption>
                </MultiCheck>
                <div className={'overlay'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    next && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/typical-lunch')}/>
                    )
                }
            </div>
        </div>
    );
}
export default Page;

