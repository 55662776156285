import './App.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import MainLayout from "./layouts/main/layout";
import AnalysisLayout from "./layouts/analysis/layout";
import GenderPage from "./pages/gender/page";
import BornPage from "./pages/born/page";
import NamePage from "./pages/name/page";
import HeightPage from "./pages/height/page";
import WeightPage from "./pages/weight/page";
import IdealWeightPage from "./pages/ideal-weight/page";
import LoosGalPage from "./pages/loos-goal/page";
import MotivationPage from "./pages/motivation/page";
import WhenPage from "./pages/when/page";
import GoalPage from "./pages/goal/page";
import EmailPage from "./pages/email/page";
import MealsCountPage from "./pages/meals-count/page";
import PrepareMealsPage from "./pages/prepare-meals/page";
import DailyDrinkPage from "./pages/daily-drink/page";
import CrabDensePage from "./pages/crab-dense/page";
import PreferencesPage from "./pages/preferences/page";
import HabitsPage from "./pages/habits/page";
import TypicalLunchPage from "./pages/typical-lunch/page";
import VegetableIntakePage from "./pages/vegetable-intake/page";
import WorkSchedulePage from "./pages/work-schedule/page";
import YourLifestylePage from "./pages/your-lifestyle/page";
import GoForWalksPage from "./pages/go-for-walks/page";
import WorkOutPage from "./pages/work-out/page";
import SleepPerNightPage from "./pages/sleep-per-night/page";
import ImproveYourSleepPage from "./pages/improve-your-sleep/page";
import AnalysisPage from "./pages/analysis/page";
import ChangeMotivationPage from "./pages/change-motivation/page";
import StatementPage from "./pages/statement/page";
import AtThisMomentPage from "./pages/at-this-moment/page";
import {Provider} from "./providers/Provider";

function App() {
    return (
        <div className="App">
            <Provider>
                <Router>
                    <Routes>
                        <Route path="/" element={
                            <MainLayout>
                                <GenderPage />
                            </MainLayout>
                        }/>
                        <Route path="/born" element={
                            <MainLayout>
                                <BornPage />
                            </MainLayout>
                        }/>
                        <Route path="/name" element={
                            <MainLayout>
                                <NamePage />
                            </MainLayout>
                        }/>
                        <Route path="/height" element={
                            <MainLayout>
                                <HeightPage />
                            </MainLayout>
                        }/>
                        <Route path="/weight" element={
                            <MainLayout>
                                <WeightPage />
                            </MainLayout>
                        }/>
                        <Route path="/loos-goal" element={
                            <MainLayout>
                                <LoosGalPage />
                            </MainLayout>
                        }/>
                        <Route path="/ideal-weight" element={
                            <MainLayout>
                                <IdealWeightPage />
                            </MainLayout>
                        }/>
                        <Route path="/motivation" element={
                            <MainLayout>
                                <MotivationPage />
                            </MainLayout>
                        }/>
                        <Route path="/when" element={
                            <MainLayout>
                                <WhenPage />
                            </MainLayout>
                        }/>
                        <Route path="/goal" element={
                            <MainLayout>
                                <GoalPage />
                            </MainLayout>
                        }/>
                        <Route path="/email" element={
                            <MainLayout>
                                <EmailPage />
                            </MainLayout>
                        }/>
                        <Route path="/meals-count" element={
                            <MainLayout>
                                <MealsCountPage />
                            </MainLayout>
                        }/>
                        <Route path="/prepare-meals" element={
                            <MainLayout>
                                <PrepareMealsPage />
                            </MainLayout>
                        }/>
                        <Route path="/daily-drink" element={
                            <MainLayout>
                                <DailyDrinkPage />
                            </MainLayout>
                        }/>
                        <Route path="/crab-dense" element={
                            <MainLayout>
                                <CrabDensePage />
                            </MainLayout>
                        }/>
                        <Route path="/preferences" element={
                            <MainLayout>
                                <PreferencesPage />
                            </MainLayout>
                        }/>
                        <Route path="/habits" element={
                            <MainLayout>
                                <HabitsPage />
                            </MainLayout>
                        }/>
                        <Route path="/typical-lunch" element={
                            <MainLayout>
                                <TypicalLunchPage />
                            </MainLayout>
                        }/>
                        <Route path="/vegetable-intake" element={
                            <MainLayout>
                                <VegetableIntakePage />
                            </MainLayout>
                        }/>
                        <Route path="/work-schedule" element={
                            <MainLayout>
                                <WorkSchedulePage />
                            </MainLayout>
                        }/>
                        <Route path="/your-lifestyle" element={
                            <MainLayout>
                                <YourLifestylePage />
                            </MainLayout>
                        }/>
                        <Route path="/go-for-walks" element={
                            <MainLayout>
                                <GoForWalksPage />
                            </MainLayout>
                        }/>
                        <Route path="/work-out" element={
                            <MainLayout>
                                <WorkOutPage />
                            </MainLayout>
                        }/>
                        <Route path="/sleep-per-night" element={
                            <MainLayout>
                                <SleepPerNightPage/>
                            </MainLayout>
                        }/>
                        <Route path="/improve-your-sleep" element={
                            <MainLayout>
                                <ImproveYourSleepPage  />
                            </MainLayout>
                        }/>
                        <Route path="/change-motivation" element={
                            <MainLayout>
                                <ChangeMotivationPage />
                            </MainLayout>
                        }/>
                        <Route path="/statement" element={
                            <MainLayout>
                                <StatementPage />
                            </MainLayout>
                        }/>
                        <Route path="/at-this-moment" element={
                            <MainLayout>
                                <AtThisMomentPage />
                            </MainLayout>
                        }/>
                        <Route path="/analysis" element={
                            <AnalysisLayout>
                                <AnalysisPage />
                            </AnalysisLayout>
                        }/>
                    </Routes>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
