import React from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import _ from 'lodash'
import {useStore} from "../../store/store";
import Picker from 'react-mobile-picker'

const MIN_WEIGHT = 30;
const MAX_WEIGHT = 301;

function Page() {
    const navigate = useNavigate();
    const weight = useStore((state) => state.weight)
    const setWeight = useStore((state) => state.setWeight)

    const handlePickerChange = ({newWeight}) => {
        console.log(newWeight)
        setWeight({...weight, value: newWeight})
    }

    return (
        <div className={'page weight'}>
            <div className={'back'} onClick={() => navigate('/height')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your weight?</div>
                <div className={'description'}>
                    This will help us understand your calorie needs
                </div>
            </div>
            
            <div className={'controls'}>
                <div className={'tabs'}>
                    <span className={cn({'active': weight.type === 'kg'})} onClick={() => {
                        setWeight({...weight, type: 'kg'})
                    }}>Kg</span>
                    <span className={cn({'active': weight.type === 'ibs'})} onClick={() => {
                        setWeight({...weight, type: 'ibs'})
                    }}>Ibs</span>
                </div>

                <input type="number" autoFocus={true} value={weight.value} placeholder={'Input weight...'} onChange={(event) => setWeight({...weight, value: event.target.value})}/>
                <div className={'line'}></div>
            </div>

            {/*<div className={'picker-wrapper'}>*/}
            {/*    <div className={'next-wrapper next-wrapper-height'}>*/}
            {/*        {*/}
            {/*            weight.value && (*/}
            {/*                <img className={'next'} src="/assets/images/next-button.png" onClick={() => navigate('/loos-goal')} alt=""/>*/}
            {/*            )*/}
            {/*        }*/}

            {/*        <Picker*/}
            {/*            value={{newWeight: weight.value}}*/}
            {/*            onChange={handlePickerChange}*/}
            {/*            wheelMode="natural"*/}
            {/*            height={150}*/}
            {/*            className={'data-picker'}*/}
            {/*        >*/}
            {/*            <Picker.Column name="newWeight" className={'col'}>*/}
            {/*                { _.range(MIN_WEIGHT, MAX_WEIGHT).map((weight) => (*/}
            {/*                    <Picker.Item key={weight} value={weight} className={'item'}>*/}
            {/*                        {({ selected }) => (*/}
            {/*                            <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{weight}</div>*/}
            {/*                        )}*/}
            {/*                    </Picker.Item>*/}
            {/*                ))}*/}
            {/*            </Picker.Column>*/}
            {/*        </Picker>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'next-wrapper'}>
                {
                    weight.value && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/loos-goal')}/>
                    )
                }
            </div>
        </div>
    );
}

export default Page;

