import React, {useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import {MultiCheck} from "../../components/ui/multi-check/multi-check";
import {MultiOption} from "../../components/ui/multi-check/option";
function Page() {
    const navigate = useNavigate();
    const [next, setNext] = useState(false);
    const preferences = useStore((state) => state.preferences)
    const setPreferences = useStore((state) => state.setPreferences)

    return (
        <div className={'page prepare-meals'}>
            <div className={'back'} onClick={() => navigate('/crab-dense')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What are your food preferences?</div>
            </div>

            <div className={'controls'}>
                <MultiCheck defaultValue={preferences} onChange={(value) => {
                    if (value.length > 0) {
                        setNext(true)
                    } else {
                        setNext(false)
                    }
                    setPreferences(value)
                }}>
                    <MultiOption value={'No any preferences'}>😶   No any preferences</MultiOption>
                    <MultiOption value={'Vegetarian'}>🥬   Vegetarian</MultiOption>
                    <MultiOption value={'Fully plant-based'}>🌱   Fully plant-based</MultiOption>
                    <MultiOption value={'Pescatarian'}>🍤   Pescatarian</MultiOption>
                    <MultiOption value={'Lactose-free'}>🥛   Lactose-free</MultiOption>
                    <MultiOption value={'Gluten-free'}>🥖   Gluten-free</MultiOption>
                    <MultiOption value={'Keto'}>🥑   Keto</MultiOption>
                    <MultiOption value={'Other'}>🍽   Other</MultiOption>
                </MultiCheck>
                <div className={'overlay'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    next && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/habits')}/>
                    )
                }
            </div>
        </div>
    );
}
export default Page;

