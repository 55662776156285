import React, {useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import {MultiCheck} from "../../components/ui/multi-check/multi-check";
import {MultiOption} from "../../components/ui/multi-check/option";
function Page() {
    const navigate = useNavigate();
    const [next, setNext] = useState(false);
    const typicalLunch = useStore((state) => state.typicalLunch)
    const setTypicalLunch = useStore((state) => state.setTypicalLunch)

    return (
        <div className={'page prepare-meals'}>
            <div className={'back'} onClick={() => navigate('/habits')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What does your typical lunch look like?</div>
            </div>

            <div className={'controls'}>
                <MultiCheck defaultValue={typicalLunch} onChange={(value) => {
                    if (value.length > 0) {
                        setNext(true)
                    } else {
                        setNext(false)
                    }
                    setTypicalLunch(value)
                }}>
                    <MultiOption value={'I tend to eat sandwiches or wraps'}>I tend to eat sandwiches or wraps</MultiOption>
                    <MultiOption value={'I tend to eat soups or salads'}>I tend to eat soups or salads</MultiOption>
                    <MultiOption value={'I tend to eat fast food'}>I tend to eat fast food</MultiOption>
                    <MultiOption value={'Other'}>Other</MultiOption>
                </MultiCheck>
                <div className={'overlay'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    next && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/vegetable-intake')}/>
                    )
                }
            </div>
        </div>
    );
}
export default Page;

