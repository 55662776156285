// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis {
  background-color: #000;
  height: 100vh;
  height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.analysis .controls {
  position: relative;
  z-index: 2;
}
.analysis .loading {
  position: absolute;
}
.analysis .wrapper-image {
  position: relative;
}
.analysis .wrapper-image .image {
  width: 100%;
}
.analysis .wrapper-image .point {
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  border: 6px #98E000 solid;
  position: absolute;
}
.analysis .wrapper-image .point span {
  background-color: #fff;
  position: absolute;
  top: -20px;
  left: 200%;
}`, "",{"version":3,"sources":["webpack://./src/pages/analysis/style.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,UAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAGI;EACE,WAAA;AADN;AAII;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AAFN;AAIM;EACE,sBAAA;EACA,kBAAA;EAAmB,UAAA;EACnB,UAAA;AADR","sourcesContent":[".analysis {\n  background-color: #000;\n  height: 100vh;\n  height: 100svh;\n  max-height: 100vh;\n  max-height: 100svh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n\n  .controls {\n    position: relative;\n    z-index: 2;\n  }\n\n  .loading {\n    position: absolute;\n  }\n\n  .wrapper-image {\n    position: relative;\n\n    .image {\n      width: 100%;\n    }\n\n    .point {\n      width: 21px;\n      height: 21px;\n      background-color: #fff;\n      border-radius: 50%;\n      border: 6px #98E000 solid;\n      position: absolute;\n\n      span  {\n        background-color: #fff;\n        position: absolute;top: -20px;\n        left: 200%;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
