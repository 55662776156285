import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const improveYourSleep = useStore((state) => state.improveYourSleep)
    const setImproveYourSleep = useStore((state) => state.setImproveYourSleep)

    return (
        <div className={'page often'}>
            <div className={'back'} onClick={() => navigate('/sleep-per-night')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>Is there anything you want to improve about your sleep?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={improveYourSleep} onChange={(value) => {
                    setImproveYourSleep(value)
                    navigate('/improve-your-sleep')
                }}>
                    <Option value={'No, i sleep well'}>No, i sleep well</Option>
                    <Option value={'Waking up tired'}>Waking up tired</Option>
                    <Option value={'Waking up during nights'}>Waking up during nights</Option>
                    <Option value={'Insomnia'}>Insomnia</Option>
                    <Option value={'Difficulty falling asleep'}>Difficulty falling asleep</Option>
                    <Option value={'Lack of sleep schedule'}>Lack of sleep schedule</Option>
                </Check>
                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

