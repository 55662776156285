import React, {Fragment, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import _ from 'lodash'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {useStore} from "../../store/store";
import Picker from 'react-mobile-picker'

const MIN_HEIGHT = 50;
const MAX_HEIGHT = 251;

gsap.registerPlugin(ScrollTrigger);

function Page() {
    const navigate = useNavigate();
    const height = useStore((state) => state.height)
    const setHeight = useStore((state) => state.setHeight)
    // const container = React.createRef();

    const handlePickerChange = ({newHeight}) => {
        setHeight({...height, value: newHeight})
    }

    const Next = () => {
        if (height.type === 'sm') {
            if (height.value) {
                return (
                    <div className={'next-wrapper'}>
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/weight')}/>
                    </div>
                )
            }
        } else if (height.type === 'inch') {
            if (height.ft && height.in) {
                return (
                    <div className={'next-wrapper'}>
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/weight')}/>
                    </div>
                )
            }
        }
    }

    // useLayoutEffect(() => {
    //     let ctx = gsap.context(() => {
    //         let items = gsap.utils.toArray(".stroke");
    //         items.forEach((item, id) => {
    //             ScrollTrigger.create({
    //                 scroller: container.current,
    //                 trigger: item,
    //                 start: 'center 79px',
    //                 end: '+=11',
    //                 toggleClass: 'active',
    //                 scrub: true,
    //                 onEnter: (self) => {
    //                     setHeight(parseInt(item.dataset.height))
    //                 },
    //                 onEnterBack: (self) => {
    //                     setHeight(parseInt(item.dataset.height))
    //                 }
    //                 //markers: true
    //             });
    //         });
    //     });
    //     return () => ctx.revert();
    // });

    return (
        <div className={'page height'}>
            <div className={'back'} onClick={() => navigate('/name')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your height?</div>
                <div className={'description'}>
                    This will help us understand your calorie needs
                </div>
            </div>
            
            <div className={'controls controlsHeight'}>
                <div className={'tabs'}>
                    <span className={cn({'active': height.type === 'sm'})} onClick={() => {
                        setHeight({...height, type: 'sm'})
                    }}>SM</span>
                    <span className={cn({'active': height.type === 'inch'})} onClick={() => {
                        setHeight({...height, type: 'inch'})
                    }}>inch</span>
                </div>

                {
                    height.type === 'sm' && (
                        <Fragment>
                            <input style={{marginTop: 40}} type="number" autoFocus={true} value={height.value} placeholder={'Input height...'} onChange={(event) => setHeight({...height, value: event.target.value})}/>
                            <div className={'line'}></div>
                        </Fragment>

                    )
                }

                {
                    height.type === 'inch' && (
                        <div className={'wrapper-inputs'}>
                            <div>
                                <div className={'item'}>
                                    <input type="number" autoFocus={true} value={height.ft} onChange={(event) => setHeight({...height, ft: event.target.value})}/>
                                    <span>ft</span>
                                </div>
                                <div className={'line'}></div>
                            </div>
                            <div>
                                <div className={'item'}>
                                    <input type="number" value={height.in} onChange={(event) => setHeight({...height, in: event.target.value})}/>
                                    <span>in</span>
                                </div>
                                <div className={'line'}></div>
                            </div>
                        </div>

                    )
                }
            </div>

            {/*<div className={'measure'}>*/}
            {/*    <span className={'current'}></span>*/}
            {/*    <div className={'content'} ref={container}>*/}
            {/*        {*/}
            {/*            _.range(MIN_HEIGHT, MAX_HEIGHT).map(value => {*/}
            {/*                if (value === 1 || value % 10 === 0) {*/}
            {/*                    return (*/}
            {/*                        <div data-height={value} key={value} className={cn('stroke main')}>*/}
            {/*                            <span>{value}</span>*/}
            {/*                        </div>*/}
            {/*                    );*/}
            {/*                } else {*/}
            {/*                    return (*/}
            {/*                        <div key={value} data-height={value} className={cn('stroke')}></div>*/}
            {/*                    );*/}
            {/*                }*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}



            {/*<div className={'picker-wrapper'}>*/}
            {/*    <div className={'next-wrapper next-wrapper-height'}>*/}
            {/*        {*/}
            {/*            height.value && (*/}
            {/*                <img className={'next'} src="/assets/images/next-button.png" onClick={() => navigate('/weight')} alt=""/>*/}
            {/*            )*/}
            {/*        }*/}

            {/*        <Picker*/}
            {/*            value={{newHeight: height.value}}*/}
            {/*            onChange={handlePickerChange}*/}
            {/*            wheelMode="natural"*/}
            {/*            height={150}*/}
            {/*            className={'data-picker'}*/}
            {/*        >*/}
            {/*            <Picker.Column name="newHeight" className={'col'}>*/}
            {/*                { _.range(MIN_HEIGHT, MAX_HEIGHT).map((height) => (*/}
            {/*                    <Picker.Item key={height} value={height} className={'item'}>*/}
            {/*                        {({ selected }) => (*/}
            {/*                            <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{height}</div>*/}
            {/*                        )}*/}
            {/*                    </Picker.Item>*/}
            {/*                ))}*/}
            {/*            </Picker.Column>*/}
            {/*        </Picker>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Next />
        </div>
    );
}

export default Page;

